var jQuery = $;
import slick from '../../node_modules/slick-carousel/slick/slick.min.js';
import fancy from '../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.js';
import floatingLabels from './floating-labels';

window.floatingLabels = floatingLabels;
const app = {
    /**
     * Initialize.
     */
    init() {

        // Check header scrolled
        this.headerScrolled();

        // Header colors for pageheader with video
        this.checkPageheader();

        // Toggle subnavigation
        this.toggleSubnavigation();

        // Smooth scroll to Anchor
        this.smoothAnchor();

        // Toggle FAQ items
        this.toggleFooterItems();

        // Initialize sliders
        this.initSliders();

        // Open Pop-ups
        this.openPopup();

        // Register our event listeners
        $(window).on('scroll', $.proxy(this.windowScrollHandler, this));
        $('.hamburger').on('click', $.proxy(this.toggleHamburgerMenu, this));
    },

    /** Handles the scroll event. */
    windowScrollHandler() {
        $('header').toggleClass('is-scrolled', $(window).scrollTop() > 0);
    },

    /** Check header scrolled */
    headerScrolled() {
        if ($(window).scrollTop() > 0) {
            $('header').toggleClass('is-scrolled');
        }
    },

    /** Open hamburger menu. */
    toggleHamburgerMenu() {
        $(".hamburger").toggleClass('is-active');
        $("nav.navigation").toggleClass('visible');
        $("header").toggleClass('is-active');
        $("body").toggleClass('is-locked');
    },

    /** Header colors for pageheader with video */
    checkPageheader() {

        let pageheaderVideo = $('.pageheader-visual');

        if (pageheaderVideo.length) {
            $("header").addClass("is-transparent");
        }
    },

    /** Toggle Subnavigation */
    toggleSubnavigation() {
        if ($(window).width() < 1025) {
            $("nav.navigation ul li.has-children > a.navlink").click(function (e) {
                e.preventDefault();
                $(this).parent().toggleClass("is-opened").find("ul").slideToggle("slow");
            });
        }
    },

    /** Smooth scroll to Anchor */
    smoothAnchor() {
        var $root = $('html, body');

        $('a[href^="#"]:not#popup-vacancy').click(function () {
            $root.animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 110
            }, 500);

            return false;
        });
    },

    /** Toggle FAQ items */
    toggleFooterItems() {

        if ($(window).width() < 768) {
            $('.footer-items-holder .footer-items .item .item-title').click(function (e) {
                e.preventDefault();
                let $this = $(this);
                if ($this.hasClass('is-opened')) {
                    $this.removeClass('is-opened');
                    $this.next().removeClass('is-opened');
                    $this.next().slideUp(350);
                } else {
                    $this.addClass('is-opened');
                    $this.next().addClass('is-opened');
                    $this.next().slideToggle(350);
                }
            });
        }
    },

    /** Initialize Sliders */
    initSliders() {
        // Gallery slider
        let $sliderGallery = $('.gallery-slider');

        if ($sliderGallery.length) {
            $sliderGallery.slick({
                infinite: true,
                centerMode: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 4500,
                arrows: false,
                dots: false,
                fade: false,
                speed: 900,
                responsive: [
                    {breakpoint: 768, settings: {slidesToShow: 1}},
                ]
            });
        }

        // Gallery slider
        let $sliderLogos = $('.logos-slider');

        if ($sliderLogos.length) {
            $sliderLogos.slick({
                infinite: true,
                centerMode: true,
                slidesToShow: 6,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 4500,
                arrows: false,
                dots: false,
                fade: false,
                speed: 900,
                responsive: [
                    {breakpoint: 768, settings: {slidesToShow: 2}},
                    {breakpoint: 1025, settings: {slidesToShow: 3}},
                ]
            });
        }
    },

    /** Open Pop-ups */
    openPopup() {

        if ($(".popup.for-vacancy").length) {
            $('.open-popup, a[href="#popup-vacancy"]').click(function (e) {
                e.preventDefault();

                $.fancybox.open({
                    buttons: [],
                    src: ".popup.for-vacancy",
                    type: "inline",
                    clickOutside: "close",
                    afterClose: function (e) {
                        $.fancybox.close();
                    }
                });
            });
        }
    },
};
$(document).ready($.proxy(app.init, app));
